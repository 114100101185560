import ErrorIcon from "@mui/icons-material/CancelOutlined";
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/WarningAmberOutlined";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Stack,
    Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @param {object} props
 * @param {object} props.logs - logs to be displayed
 * @param {string} props.filterValue - value to of log level to be filtered for
 * @param {boolean} props.loading - boolean value to make containers appear like they're loading
 * @returns {JSX.Element} container showing logs for specific document
 */

const InvoiceLogs = ({ logs, filterValue, loading }) => {
    //i18n
    const { t } = useTranslation();

    //data
    const [logsCopy, setLogsCopy] = useState([]);

    useEffect(() => {
        setLogsCopy(logs);
    }, [logs]);

    // filter logs based on filter value
    const filterLogs = (filter) => {
        if (filter !== "ALL") {
            const filteredLogs = logs.filter((log) => log["level"] === filter);
            console.log(filteredLogs);
            setLogsCopy(filteredLogs);
        } else {
            setLogsCopy(logs);
        }
    };

    useEffect(() => {
        filterLogs(filterValue);
        console.log("filtering");
    }, [filterValue]);

    return !loading && logsCopy.length !== 0 ? (
        logsCopy.map(
            (log, index) =>
                log["level"] !== "DEBUG" && (
                    <Accordion className="logs">
                        <AccordionSummary
                            className="items"
                            expandIcon={<ExpandMoreIcon className="level" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Stack direction="row" gap={1} alignItems="center">
                                {log["level"] === "SUCCESS" ? (
                                    <SuccessIcon className="level success" />
                                ) : log["level"] === "INFO" ? (
                                    <InfoIcon className="level info" />
                                ) : log["level"] === "WARNING" ? (
                                    <WarningIcon className="level warning" />
                                ) : (
                                    <ErrorIcon className="level error" />
                                )}
                                {log["message"]}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                direction="column"
                                gap={1}
                                justifyContent="start"
                                paddingLeft={5}
                            >
                                <Typography variant="body1" className="items">
                                    {`${t("log_level")}: ${log["level"]}`}
                                </Typography>
                                <Typography variant="body1" className="items">
                                    {`${t("timestamp")}: ${log["timestamp"]}`}
                                </Typography>
                                <Typography variant="body1" className="items">
                                    {`${t("user")}: ${log["user_id"]}`}
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )
        )
    ) : !loading && logsCopy.length === 0 ? (
        <Container className="logsFilter empty">
            <Typography variant="body1" className="items">
                {t("no_logs")}
            </Typography>
        </Container>
    ) : (
        <Container className="logsFilter empty">
            <Typography variant="body1" className="items">
                ...
            </Typography>
        </Container>
    );
};

export default InvoiceLogs;
