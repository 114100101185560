import { useMediaQuery } from "@mui/material";

import { useEffect, useState } from "react";

import theme from "../assets/muiCustomTheme.js";

/**
 * @param {object} props
 * @param {string} props.data - Visualization.html from DocumentAttachments
 * @returns {JSX.Element} Iframe where the html is going to be shown in
 */

const VisualFrame = ({ visualization }) => {
  const [src, setSrc] = useState("");

  console.log(visualization);

  useEffect(() => {
    // Decode base64 data into a binary string
    const binaryString = atob(visualization?.data);
    // Convert binary string to an array of bytes
    const binaryArray = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
    // Decode array of bytes into UTF-8 text
    const decodedData = new TextDecoder("utf-8").decode(binaryArray);

    // Create a blob URL from the HTML string
    const blob = new Blob([decodedData], { type: visualization?.mimeType });
    const url = URL.createObjectURL(blob);
    setSrc(url);

    // Cleanup the blob URL when the component unmounts or data changes
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [visualization]);

  // breakpoints for responsive design
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isXxl = useMediaQuery(theme.breakpoints.up("xxl"));

  let height;

  // setting width and height based on screensize
  if (isXxl) {
    height = "1120";
  } else if (isXl) {
    height = "910";
  } else if (isLg) {
    height = "1270";
  } else if (isMd) {
    height = "810";
  } else {
    height = "910";
  }

  return (
    <iframe
      src={
        visualization?.mimeType === "text/html"
          ? src
          : `data:application/pdf;base64,${visualization?.data}`
      }
      width={"100%"}
      height={height}
      title="eInvoice Visualization"
      style={{
        backgroundColor: visualization?.mimeType === "application/pdf" ? "unset" : "white",
        color: visualization?.mimeType === "application/pdf" ? "black" : "unset"
      }}
    ></iframe>
  );
};

export default VisualFrame;
