import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Box, Button, Grid, TextField, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "notistack";

import { useLocation } from "react-router-dom";

import { useAuth } from "../AuthProvider";
import PasswordTextField from "../components/PasswordTextField";

export default function SignIn() {
  // i18n
  const { t } = useTranslation();

  const { login, request } = useAuth();
  const [azureConfiguration, setAzureConfiguration] = useState(false);

  // endpoints in use
  const endpoint_signin = `${process.env.REACT_APP_BACKEND_URL}/signin`;
  const endpoint_azure_signin = `${process.env.REACT_APP_BACKEND_URL}/signin/azure`;
  const endpoint_azure_configuration = `${process.env.REACT_APP_BACKEND_URL}/signin/azure_credentials`;

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  if (location.pathname === "/signin/azure_error") {
    enqueueSnackbar(t("azure-signin-error"), {
      variant: "error",
      preventDuplicate: true
    });
  }

  // form data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    await login(endpoint_signin, formData);
  };

  const handleAzureLogin = async () => {
    window.location.href = endpoint_azure_signin;
  };

  const fetchData = async () => {
    const response = await request("get", endpoint_azure_configuration, null, null, []);

    if (response?.status === 200 || response?.status === 204) {
      if (response.data === "Azure configuration available") {
        setAzureConfiguration(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="center"
          md={5}
          xl={3}
          marginTop={{ lg: 7, xl: 5, xxl: 10 }}
        >
          <Avatar className="lock">
            <LockOutlinedIcon className="lock" />
          </Avatar>

          <Typography component="h1" variant="h2" className="signin">
            {t("sign_in")}
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2, width: "100%" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={t("email")}
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <PasswordTextField
              margin="normal"
              required
              fullWidth
              label={t("password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: {
                  md: "16px",
                  lg: "18px",
                  xl: "14px",
                  xxl: "18px"
                }
              }}
            >
              {t("sign_in")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              // startIcon={<AzureLogo />}
              sx={{
                mt: 1,
                mb: 2,
                fontSize: {
                  md: "16px",
                  lg: "18px",
                  xl: "14px",
                  xxl: "18px"
                },
                display: !azureConfiguration ? "none" : "block"
              }}
              onClick={handleAzureLogin}
            >
              {t("azure")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
