import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

/**
 * @param {Object} props
 * @param {Object} props.rules
 * @returns
 */
const PasswordRules = ({ rules }) => {
  // i18n
  const { t } = useTranslation();

  return (
    Object.keys(rules).length > 0 && (
      <Box
        elevation={3}
        sx={{
          padding: "16px",
          borderRadius: "4px",
          border: "1px solid #ddd",
          backgroundColor: "#efefef",
          color: "black"
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: "8px" }}>
          {t("password_rules")}
        </Typography>
        <ul style={{ fontSize: "1rem", marginBottom: "8px" }}>
          {rules["length_requirement"] && (
            <li>{t("length_requirement", { count: rules["length_requirement"] })}</li>
          )}
          {rules["require_uppercase"] && <li>{t("require_uppercase")}</li>}
          {rules["require_lowercase"] && <li>{t("require_lowercase")}</li>}
          {rules["require_digit"] && <li>{t("require_digit")}</li>}
          {rules["require_special_character"] && <li>{t("require_special_character")}</li>}
        </ul>
      </Box>
    )
  );
};

export default PasswordRules;
