import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { useAuth } from "../AuthProvider";

/**
 * @param {object} props
 * @param {string[]} props.settings - list of settings
 * @param {boolean} props.menuVisibility - boolean value whether dropdown menu is visible
 * @param {Function} props.handleOpen - function to handle menu opening
 * @param {Function} props.handleClose - function to handle menu closing
 * @returns {JSX.Element} - custom button for navbar to open menu with different setting options
 */
const AccountButton = ({
    settings,
    menuVisibility,
    handleOpen,
    handleClose,
}) => {
    // i18n
    const { t } = useTranslation();

    const { logout } = useAuth();

    return (
        <>
            {/* button to open profile dropdown menu */}
            <IconButton
                onClick={handleOpen}
                sx={{ p: 0, ml: "1rem" }}
                aria-label="open profile dropdown menu"
            >
                <AccountCircleIcon className="loginIcon" />
            </IconButton>

            {/* profile dropdown menu */}
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={menuVisibility}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(menuVisibility)}
                onClose={handleClose}
            >
                {settings.map((setting) => (
                    <MenuItem
                        key={setting}
                        onClick={handleClose}
                        aria-label={
                            setting === "Logout"
                                ? "log out"
                                : `redirect to ${setting}`
                        }
                    >
                        <Link
                            component="button"
                            onClick={() => {
                                setting === "Logout" ? logout() : console.log();
                            }}
                            to={setting === "Profile" ? "/profile" : ""}
                            style={{
                                gap: "8px",
                                alignItems: "center",
                            }}
                        >
                            {setting === "Logout" ? (
                                <LogoutIcon className="userMenu" />
                            ) : setting === "Profile" ? (
                                <AccountCircleIcon className="userMenu" />
                            ) : (
                                <></>
                            )}
                            <Typography variant="body1" className="navbar">
                                {setting === "Logout"
                                    ? "Logout"
                                    : t(`${setting.toLocaleLowerCase()}_title`)}
                            </Typography>
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default AccountButton;
