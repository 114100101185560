import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import JSONViewer from "./JsonViewer";
import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {boolean} props.open - boolean value whether dialogue is open
 * @param {Function} props.handleClose - function to handle closing dialogue
 * @param {Function} props.handleCreate - function to call when 'confirm & create' button is pressed
 * @returns {JSX.Element} dialogue when 'create new module' button is pressed to build module
 */
const ModuleCreate = ({ open, handleClose, handleCreate }) => {
  const { t } = useTranslation();

  const [moduleName, setModuleName] = useState("");
  const [config, setConfig] = useState({});

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        setConfig(json);
      } catch (err) {
        alert(t("module_json_parse_failure"));
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const inputRef = React.useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleConfigEdit = (c) => {
    setConfig(c);
  };

  useEffect(() => {
    setModuleName("");
    setConfig({
      exampleKey: "when creating a new property, enter `{}` as it's value to create an object"
    });
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Stack direction="column">
          {/* dialog header */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ marginBottom: "30px" }}
          >
            {/* dialog title */}
            <Typography
              variant="h3"
              className="dialogHeadline"
              sx={{ marginLeft: "10px !important" }}
            >
              {t("module_create")}
            </Typography>

            {/* close dialog button */}
            <TooltipButton
              tooltip={`${t("close")}`}
              handleClick={handleClose}
              icon="close"
              iconStyle={{ color: "white !important" }}
              buttonClass="cardIcon"
              iconClass="cardIcon"
              ariaLabel="close current create dialogue"
            />
          </Stack>

          {/* module form */}
          <Stack px="10px" spacing={5} mb={5}>
            <TextField
              variant="standard"
              label={t("module_name")}
              value={moduleName}
              type="text"
              onChange={(e) => {
                setModuleName(e.target.value);
              }}
            />
            <Stack spacing={2}>
              <Typography variant="h5" className="objectHeadline">
                {t("Configuration")}
              </Typography>

              <JSONViewer config={config} isEditable={true} updateConfig={handleConfigEdit} />
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Button
              id="file"
              variant="contained"
              color="secondary"
              className="module"
              tabIndex={-1}
              onClick={handleButtonClick}
              sx={{ width: "max-content", alignSelf: "end" }}
              aria-label="upload config json"
            >
              {t("module_upload_config")}
              <VisuallyHiddenInput
                type="file"
                accept=".json"
                ref={inputRef}
                onChange={handleFileUpload}
              />
            </Button>

            <Button
              variant="contained"
              color="primary"
              className="module"
              onClick={() => {
                handleCreate(moduleName, config);
              }}
              sx={{ width: "max-content", alignSelf: "end" }}
              aria-label="confirm create"
            >
              {t("module_create_confirm")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ModuleCreate;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});
