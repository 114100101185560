import { InputAdornment, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState } from "react";

import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {boolean} props.fullWidth - If ``true``, the input will take up the full width of its container.
 * @param {boolean} props.required - If ``true``, the label is displayed as required and the ``input`` element is required.
 * @param {string} props.autoComplete - This prop helps users to fill forms faster, especially on mobile devices. The name can be confusing, as it's more like an autofill.
 * @param {string} props.name - Name attribute of the ``input`` element.
 * @param {string} props.label - The label content.
 * @param {string} props.value - The value of the ``input`` element, required for a controlled component.
 * @param {Function} props.onChange - Callback fired when the value is changed.
 * @param {String} props.margin - If ``dense`` or ``normal``, will adjust vertical spacing of this and contained components.
 * @returns
 */
const PasswordTextField = ({
  fullWidth = false,
  required = false,
  autoComplete,
  name,
  label,
  value,
  onChange,
  margin
}) => {
  // i18n
  const { t } = useTranslation();

  const [passwordVisible, togglePasswordVisible] = useState(false);

  return (
    <TextField
      fullWidth={fullWidth}
      required={required}
      autoComplete={autoComplete}
      type={passwordVisible ? "text" : "password"}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      margin={margin}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TooltipButton
              iconStyle={{ color: "white" }}
              ariaLabel="toggle password visibility"
              tooltip={passwordVisible ? t("hide_password") : t("show_password")}
              icon={passwordVisible ? "visibilityOff" : "visibility"}
              handleClick={() => togglePasswordVisible(!passwordVisible)}
            />
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordTextField;
