import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAuth } from "../AuthProvider";

import PasswordRules from "../components/PasswordRules";
import PasswordTextField from "../components/PasswordTextField";
import TooltipButton from "../components/TooltipButton";

const Profile = () => {
  // i18n
  const { t } = useTranslation();

  const { request } = useAuth();

  // endpoints in use
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/profile`;
  const endpoint_api = `${process.env.REACT_APP_BACKEND_URL}/api`;

  // all users from database
  const [loadingUser, setLoadingUser] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    apiKey: ""
  });
  const [copy, setCopy] = useState({
    firstName: "",
    lastName: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  // when password does not satisfy security requirements, a pop up opens
  const [passwordRules, setPasswordRules] = useState({});

  // value for copy button
  const [copyIcon, setCopyIcon] = useState(false);

  const fetchData = async (init = false) => {
    if (init) {
      setLoadingUser(true);
    }

    const response = await request("GET", endpoint, null, null, [
      {
        statusCode: 400,
        type: "error",
        message: t("load_failure", {
          prop: t("user", { count: 1 })
        })
      }
    ]);

    if (response?.status === 200 || response?.status === 204) {
      setFormData((prev) => ({
        ...prev,
        firstName: response.data?.[0]?.FirstName,
        lastName: response.data?.[0]?.LastName,
        email: response.data?.[0]?.Email,
        apiKey: response.data?.[0]?.ApiKey
      }));
      setCopy((prev) => ({
        ...prev,
        firstName: response.data?.[0]?.FirstName,
        lastName: response.data?.[0]?.LastName
      }));
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const compareData = () => {
    const copyKeys = Object.keys(copy);
    const dataKeys = Object.keys(formData);

    const sharedKeys = copyKeys.filter((key) => dataKeys.includes(key));

    return sharedKeys.every((key) => copy[key] === formData[key]);
  };

  const reset = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      firstName: copy.firstName,
      lastName: copy.lastName,
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    }));
  };

  const handleEdit = async (formData) => {
    const body = {
      user_fname: formData.firstName,
      user_lname: formData.lastName,
      user_email: formData.email,
      user_cupassword: formData.currentPassword,
      user_newpassword: formData.newPassword,
      user_conpassword: formData.confirmPassword
    };

    const response = await request("put", endpoint, body, "application/json", [
      {
        statusCode: 200,
        type: "success",
        message: t("edit_success", {
          prop: t("user", { count: 1 })
        })
      },
      {
        statusCode: 400,
        type: "error",
        message: t("edit_failure", {
          prop: t("user", { count: 1 })
        })
      },
      {
        statusCode: 403
      },
      {
        statusCode: 422,
        type: "warning",
        message: ["password", t("user_edit_invalid_password"), t("user_creation_invalid_value")]
      }
    ]);

    if (response?.status === 200) {
      reset();
      fetchData();
    } else if (response?.status === 403) {
      setPasswordRules(response.data?.detail);
    }
  };

  const handleApiKey = async () => {
    const response = await request("put", endpoint_api, {}, "application/json", [
      {
        statusCode: 200,
        type: "success",
        message: t("create_success", {
          prop: "API Key"
        })
      },
      {
        statusCode: 400,
        type: "error",
        message: t("create_failure", {
          prop: "API Key"
        })
      }
    ]);

    if (response?.status === 200) {
      fetchData();
    }
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyIcon(true);

        setTimeout(() => {
          setCopyIcon(false);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {/* main container */}
      <Stack
        direction="column"
        spacing={1}
        alignSelf="center"
        sx={{ width: { md: "100%", lg: "90%", xl: "70%" } }}
      >
        {/* heading */}
        <Typography variant="h1" className="pageHeadline">
          {t("profile_title")}
        </Typography>

        <Box
          component="form"
          className="profile"
          noValidate
          sx={{
            mt: 3,
            paddingBottom: "15px",
            borderBottom: "1px solid grey"
          }}
        >
          <Typography variant="h2" className="profile">
            {t("change_name")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="firstName"
                label={t("first_name")}
                value={loadingUser ? "..." : formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="lastName"
                label={t("last_name")}
                value={loadingUser ? "..." : formData.lastName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component="form"
          className="profile"
          noValidate
          sx={{
            mt: "40px !important",
            paddingBottom: "15px",
            borderBottom: "1px solid grey"
          }}
        >
          <Typography variant="h2" className="profile">
            {t("email")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                className="profile"
                disabled
                fullWidth
                name="email"
                value={loadingUser ? "..." : formData.email}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component="form"
          className="profile"
          noValidate
          sx={{
            mt: "40px !important",
            paddingBottom: "15px",
            borderBottom: "1px solid grey"
          }}
        >
          <Typography variant="h2" className="profile">
            {t("change_password")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <PasswordTextField
                fullWidth
                name="currentPassword"
                label={t("current_password")}
                value={formData.currentPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item display="flex" flexDirection="column" gap={2} xs={12} sm={6}>
              <Grid item sm={12}>
                <PasswordTextField
                  fullWidth
                  name="newPassword"
                  label={t("new_password")}
                  value={formData.newPassword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={12}>
                <PasswordTextField
                  fullWidth
                  name="confirmPassword"
                  label={t("confirm_password")}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={12}>
                <PasswordRules rules={passwordRules} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          component="form"
          className="profile"
          noValidate
          sx={{
            mt: "40px !important",
            paddingBottom: "15px",
            borderBottom: "1px solid grey"
          }}
        >
          <Typography variant="h2" className="profile">
            API Key
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                className="profile"
                type={loadingUser ? "text" : "password"}
                disabled
                fullWidth
                name="apiKey"
                value={loadingUser ? "..." : formData.apiKey}
              />
            </Grid>
            <Grid item xs={12} sm={6} alignItems="center" display="flex">
              <TooltipButton
                tooltip={t("copy")}
                icon={copyIcon ? "check" : "copy"}
                ariaLabel="copy to clipboard"
                handleClick={() => copyToClipboard(formData.apiKey)}
                iconStyle={{
                  color: copyIcon ? "green" : "white"
                }}
              />
              <TooltipButton
                tooltip={t("regenerate")}
                icon="reload"
                ariaLabel="regenerate API key"
                handleClick={() => handleApiKey()}
                iconStyle={{
                  color: "white"
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component="form"
          className="profile"
          noValidate
          sx={{
            mt: "20px !important"
          }}
          display="flex"
          gap={2}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={compareData()}
            sx={{ mt: 3 }}
            onClick={() => handleEdit(formData)}
          >
            {t("save_changes")}
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={compareData()}
            sx={{ mt: 3, display: compareData() ? "none" : "block" }}
            onClick={() => reset()}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default Profile;
