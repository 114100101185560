import {
    Button,
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/CancelOutlined";
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/WarningAmberOutlined";

import { useTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";

import { formatCurrency, formatDateTime } from "../utils";
import InvoiceLogs from "./InvoiceLogs";

/**
 * @param {object} props
 * @param {object} props.data - invoice information
 * @param {object[]} props.items - array of invoice item objects
 * @param {object[]} props.attachments - array of attachment objects
 * @param {object[]} props.links - array of invoice link objects
 * @param {object[]} props.logs - array of log objects
 * @param {string} props.headline - string to be set for container headline
 * @param {boolean} props.loading - boolean value to make containers appear like they're loading
 * @returns {JSX.Element} container showing information about invoices
 */

const InvoiceDetail = ({
    data,
    items,
    attachments,
    headline,
    links,
    logs,
    loading,
}) => {
    const { t } = useTranslation();

    const [groupedData, setGroupedData] = useState({});
    const [lineNumbers, setLineNumbers] = useState([]);

    const [filter, setFilter] = useState("ALL");

    const handleFilter = (event, newFilter) => {
        setFilter(newFilter);
        console.log(filter);
    };

    // groups invoice item objects by line number
    const groupByLineNumber = (items) => {
        if (items && Array.isArray(items)) {
            return items.reduce((acc, item) => {
                const { LineNumber, FieldName, FieldValue } = item;
                if (!acc[LineNumber]) {
                    acc[LineNumber] = {};
                }
                acc[LineNumber][FieldName] = FieldValue;
                return acc;
            }, {});
        }
    };

    // calculates tax amount for each invoice item
    const calculateTaxAmount = (netAmount, taxRate) => {
        if (netAmount === "None" || taxRate === "None") {
            return "";
        } else {
            let taxAmount = ((netAmount * taxRate) / 100).toString();
            return formatCurrency(taxAmount);
        }
    };

    // function to make download button for attachments work
    const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    };

    useEffect(() => {
        const newGroupedData = groupByLineNumber(items);
        setGroupedData(newGroupedData);
        if (newGroupedData) {
            setLineNumbers(Object.keys(newGroupedData));
        }
    }, [items]);

    return (
        // main container
        <Container
            className="detailGroup"
            style={{ width: "100%", overflowX: "auto" }}
        >
            {/* container header */}
            <Typography variant="h3" className="detailHeadline">
                {t(`${headline}`)}
            </Typography>

            {/* mapping through array of invoice info objects */}
            {data !== undefined && (
                <Table
                    className="detailGroup"
                    style={{ width: "100%", tableLayout: "fixed" }}
                >
                    <TableBody>
                        {!loading ? (
                            data.map((obj, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        className={
                                            "detailGroup " +
                                            (headline === "billing_details"
                                                ? "bilDetL"
                                                : "finTaxL")
                                        }
                                    >
                                        <Typography
                                            variant="body1"
                                            className="detailText"
                                        >
                                            {t(`${Object.values(obj)[0]}`)}
                                        </Typography>
                                    </TableCell>

                                    <TableCell
                                        className={
                                            "detailGroup " +
                                            (headline !== "billing_details"
                                                ? "finTaxR"
                                                : "")
                                        }
                                    >
                                        <Container
                                            className="detailText"
                                            sx={{
                                                justifyContent:
                                                    headline === "financials" ||
                                                    headline === "tax_details"
                                                        ? "end"
                                                        : "start",
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                className="detailText"
                                            >
                                                {(() => {
                                                    const values = obj
                                                        ? Object.values(obj)
                                                        : [];
                                                    const firstValue = values
                                                        ? values[0]
                                                        : "";
                                                    const secondValue = values
                                                        ? values[1]
                                                        : "";

                                                    if (
                                                        secondValue !== "" &&
                                                        secondValue !== "None"
                                                    ) {
                                                        if (
                                                            firstValue ===
                                                            "DocDate"
                                                        ) {
                                                            return formatDateTime(
                                                                secondValue
                                                            ); // show formatted value when date
                                                        } else if (
                                                            firstValue.includes(
                                                                "Amount"
                                                            ) ||
                                                            firstValue.includes(
                                                                "FieldValue"
                                                            )
                                                        ) {
                                                            return formatCurrency(
                                                                secondValue
                                                            ); // show formatted value when currency
                                                        } else {
                                                            return secondValue; // show value directly
                                                        }
                                                    } else {
                                                        return "\u00A0"; // placeholder for empty field
                                                    }
                                                })()}
                                            </Typography>
                                        </Container>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    className={
                                        "detailGroup " +
                                        (headline === "billing_details"
                                            ? "bilDetL"
                                            : "finTaxL")
                                    }
                                >
                                    <Typography
                                        variant="body1"
                                        className="detailText"
                                    >
                                        {"\u00A0"}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    className={
                                        "detailGroup " +
                                        (headline !== "billing_details"
                                            ? "finTaxR"
                                            : "")
                                    }
                                >
                                    <Container
                                        className="detailText"
                                        sx={{
                                            justifyContent:
                                                headline === "financials" ||
                                                headline === "tax_details"
                                                    ? "end"
                                                    : "start",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            className="detailText"
                                        >
                                            {"\u00A0"}
                                        </Typography>
                                    </Container>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}

            {/* mapping through invoice items */}
            {items !== undefined && (
                <Table
                    className="invoiceItems"
                    style={{ width: "100%", tableLayout: "fixed" }}
                >
                    <TableHead>
                        <TableRow>
                            {!loading ? (
                                <>
                                    <TableCell className="items itemHead pos"></TableCell>
                                    <TableCell className="items itemHead unit">
                                        {t("ItemNetPrice")}
                                    </TableCell>
                                    <TableCell className="items itemHead quan">
                                        {t("LineQuantity")}
                                    </TableCell>
                                    <TableCell className="items itemHead net">
                                        {t("LineAmount")}
                                    </TableCell>
                                    <TableCell className="items itemHead rate">
                                        {t("ItemTaxRate")} (%)
                                    </TableCell>
                                    <TableCell className="items itemHead am">
                                        {t("ItemTaxAmount")}
                                    </TableCell>
                                </>
                            ) : (
                                <TableCell
                                    className="items itemHead"
                                    colSpan={6}
                                ></TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!loading && lineNumbers.length !== 0 ? (
                            lineNumbers.map((lineNumber) => {
                                const row = groupedData[lineNumber];
                                return (
                                    <TableRow key={lineNumber}>
                                        <TableCell className="items">
                                            {lineNumber}
                                        </TableCell>
                                        <TableCell className="items">
                                            {row["ItemNetPrice"] !== "None"
                                                ? formatCurrency(
                                                      row["ItemNetPrice"]
                                                  )
                                                : ""}
                                        </TableCell>
                                        <TableCell className="items">
                                            {row["LineQuantity"] !== "None"
                                                ? formatCurrency(
                                                      row["LineQuantity"]
                                                  )
                                                : ""}
                                        </TableCell>
                                        <TableCell className="items">
                                            {row["LineAmount"] !== "None"
                                                ? formatCurrency(
                                                      row["LineAmount"]
                                                  )
                                                : ""}
                                        </TableCell>
                                        <TableCell className="items">
                                            {row["ItemTaxRate"] !== "None"
                                                ? row["ItemTaxRate"]
                                                : ""}
                                        </TableCell>
                                        <TableCell className="items">
                                            {calculateTaxAmount(
                                                row["LineAmount"],
                                                row["ItemTaxRate"]
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6}></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}

            {/* mapping through DocumentLinks */}
            {links !== undefined && (
                <Table
                    className="invoiceItems"
                    style={{ width: "100%", tableLayout: "fixed" }}
                >
                    <TableHead>
                        <TableRow>
                            {!loading ? (
                                <>
                                    {/* <TableCell className='items itemHead pos'></TableCell> */}
                                    <TableCell className="items itemHead type">
                                        {t("type")}
                                    </TableCell>
                                    <TableCell className="items itemHead id">
                                        ID
                                    </TableCell>
                                    <TableCell className="items itemHead time ">
                                        {t("link_create_timestamp")}
                                    </TableCell>
                                </>
                            ) : (
                                <TableCell
                                    className="items itemHead"
                                    colSpan={3}
                                ></TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!loading && links.length !== 0 ? (
                            links.map((link, index) => (
                                <TableRow key={index}>
                                    {/* <TableCell className='items'></TableCell> */}
                                    <TableCell className="items">
                                        {link["LinkObjectType"] !== "None"
                                            ? link["LinkObjectType"]
                                            : ""}
                                    </TableCell>
                                    <TableCell className="items id">
                                        {link["LinkObjectId"] !== "None"
                                            ? link["LinkObjectId"]
                                            : ""}
                                    </TableCell>
                                    <TableCell className="items">
                                        {link["LinkCreateTimestamp"] !== "None"
                                            ? formatDateTime(
                                                  link["LinkCreateTimestamp"]
                                              )
                                            : ""}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3}></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
            {/* mapping through attachments */}
            {attachments !== undefined && (
                <Table
                    className="invoiceItems"
                    style={{ width: "100%", tableLayout: "fixed" }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className="items itemHead"></TableCell>
                            <TableCell className="items itemHead"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!loading ? (
                            attachments.map((item, index) => (
                                <TableRow key={index} className="itemRow">
                                    <TableCell className="items">
                                        {item["AttachmentName"]}
                                    </TableCell>
                                    <TableCell className="items">
                                        <Button
                                            className="invAttachment"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                let blob;

                                                blob = base64ToBlob(
                                                    item["AttachmentData"],
                                                    item["MIMEType"]
                                                );

                                                const url =
                                                    URL.createObjectURL(blob);
                                                const link =
                                                    document.createElement("a");
                                                link.href = url;
                                                link.setAttribute(
                                                    "download",
                                                    item["AttachmentName"]
                                                );
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                URL.revokeObjectURL(url);
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    className="items"
                                    colSpan={2}
                                ></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}

            {/* mapping through Logs for document */}
            {logs !== undefined && (
                <Container className="logs">
                    <Container className="logsFilter">
                        {!loading && logs.length > 0 ? (
                            <ToggleButtonGroup
                                exclusive
                                value={filter}
                                onChange={handleFilter}
                                className="logs"
                            >
                                <ToggleButton value="ALL" className="logs">
                                    All
                                </ToggleButton>
                                <ToggleButton value="SUCCESS" className="logs">
                                    <SuccessIcon className="level success" />
                                </ToggleButton>
                                <ToggleButton value="INFO" className="logs">
                                    <InfoIcon className="level info" />
                                </ToggleButton>
                                <ToggleButton value="WARNING" className="logs">
                                    <WarningIcon className="level warning" />
                                </ToggleButton>
                                <ToggleButton value="ERROR" className="logs">
                                    <ErrorIcon className="level error" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        ) : (
                            <Typography variant="body1"></Typography>
                        )}
                    </Container>

                    <InvoiceLogs
                        logs={logs}
                        loading={loading}
                        filterValue={filter}
                    />
                </Container>
            )}
        </Container>
    );
};

export default InvoiceDetail;
